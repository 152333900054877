import { addScript } from './utils/lazyLoadScript';
import { postIdentifyEvent, postAliasEvent } from './utils/analytics';

const omEventListener = (function() {
  document.addEventListener('om.Optin.init.submit', function(event) {
    // Get the e-mail submitted by the user
    const email = event.detail.Optin.data.fields.email;
    // Identify in Analytics if we can
    postAliasEvent(email);
    postIdentifyEvent(email, {email: email});
  });
});

const initOptinMonster = () => {
  // load the external script
  const omUrl = "https://a.omappapi.com/app/js/api.min.js";
  const omOpts = {
    data: {
      account: "3671",
      user: "29868"
    }
  };
  addScript(omUrl, omOpts, omEventListener);
};

export default initOptinMonster;
