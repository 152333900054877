export const addScript = (fileUrl, opts, callback) => {
  let element = document.createElement('script');
  element.src = fileUrl;
  element.defer = true;
  if (opts?.id) element.id = opts.id;
  if (opts?.data) {
    for (const [key, value] of Object.entries(opts.data)) {
      element.setAttribute(`data-${key}`, value);
    }
  }
  if (callback) {
    element.onload = element.onreadystatechange = function() {
      if (!this.readyState || this.readyState === 'complete') {
        // file has loaded so run callback
        callback();
      }
    };
  }
  document.body.appendChild(element); // add script to the page
};
