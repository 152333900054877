import initZendesk from './zendesk';
import initOptinMonster from './optin_monster';

let loaded = false;
const mousemoveTimeout = 10000;
const loadTimeout = 18000;

const lazyLoadScripts = () => {
  if (!loaded) {
    loaded = true;
    // Lazy load external scripts
    window.removeEventListener('scroll', lazyLoadScripts, false);
    window.removeEventListener('mousemove', lazyLoadScriptsMouseMove, false);
    setTimeout(function() {
      initZendesk();
      initOptinMonster();
    }, 1000);
  }
};

const lazyLoadScriptsFallback = () => {
  // Lazy load external scripts, fallback if no scroll event
  setTimeout(lazyLoadScripts, loadTimeout);
};

const lazyLoadScriptsMouseMove = () => {
  // Lazy load external scripts after mouse move event
  window.removeEventListener('mousemove', lazyLoadScriptsMouseMove, false);
  setTimeout(lazyLoadScripts, mousemoveTimeout);
};

if (window.addEventListener) {
  window.addEventListener('scroll', lazyLoadScripts, { passive: true });
  window.addEventListener('mousemove', lazyLoadScriptsMouseMove, false);
  window.addEventListener('load', lazyLoadScriptsFallback, false);
}
