/* eslint-disable no-undef */
import { addScript } from './utils/lazyLoadScript';

const runWidgetLogic = function() {
  'use strict';
  var module = {};
  if (zE) {
    /**
     * Change the widget settings.
     * @params {boolean} HCSearch - enable HC Search?
     * @params {boolean} chat - enable chat?
     * @params {boolean} contactForm - enable contact form?
     */
    configureWidget(true, true, true);
    zE('webWidget', 'setLocale', getLanguage());
  } else {
    console.log('ZenDesk: Web widget is not running on the page.');
  }

  function getLanguage() {
    /*
      This will check the url and get the language from the pathname
      expected urls to be in the format of /gb/en/....
      so /<country>/<language>/<rest of path>/
     */
    let path = window.location.pathname.split('/');
    path = path.filter(x => x.length > 0);
    if (path.length > 1) {
      return path[1];
    } else {
      return 'en';
    }
  }
  function configureWidget(HCSearch, chat, contactForm) {
    // API configuration
    // eslint-disable-next-line one-var
    const themeOpts = { theme: '#20847c' }; // verdigris
    let widgets = {
        webWidget: {color: themeOpts, launcher: {}, helpCenter: {}, chat: {}, contactForm: {}},
      },
      ww = widgets.webWidget;
    ww.launcher.label = {en: 'Help'}; // Web Widget button text
    ww.launcher.chatLabel = {en: 'Chat'}; // Chat button text - When the Help Center Search option is disabled
    ww.contactOptions = {
      enabled: true,
    };
    ww.helpCenter.chatButton = {en: 'Chat with a person now'}; // Chat button text - when the Help Center Search option is enabled
    ww.helpCenter.title = {en: 'Help'}; // Web Widget header text
    if (!HCSearch) ww.helpCenter.suppress = true;
    if (!chat) ww.chat.suppress = true;
    else zopimConfig();
    if (!contactForm) ww.contactForm.suppress = true;
    window.zESettings = widgets;
  }
  function zopimConfig() {
    zE(function() {
      $zopim(function() {
        var zl = $zopim.livechat;
        zl.setOnConnected(function() {
          let languageCode = document.getElementById('#localisePicker')?.dataset.language;
          if (languageCode === undefined) {
            languageCode = 'en';
          }
          let departmentName = 'UK'; // default to department UK
          if (languageCode === 'fr') departmentName = 'France';
          var departmentStatus = zl.departments.getDepartment(departmentName);
          if (departmentStatus.status === 'offline') zl.setStatus('offline');
          // Set the account status to 'offline'
          else {
            zl.setLanguage(languageCode); // Set language to English:
            // zl.window.setTitle('Patch Support'); // Set the Window Title:
            // zl.addTags("brand_name"); // add a tag
            zl.departments.filter(''); // Hide the Drop-down list option in the Pre-Chat Form to select the Department
            zl.departments.setVisitorDepartment(departmentName); // Automatically set the Department
          }
        });
      });
    });
  }
  return module;
};

const initZendesk = () => {
  // load the external script
  const zeUrl = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskWebWidgetKey}`;
  const zeOpts = { id: 'ze-snippet' };
  addScript(zeUrl, zeOpts, runWidgetLogic);
};

export default initZendesk;
